import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';
import mask from '../images/svgs/hero-mask.svg';
import inverseMask from '../images/svgs/inverse-mask.svg';

const StyledHero = styled.div<HeroProps>`
    .flip {
        transform: scaleX(-1);
    }

    .services-hero-image {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 90vw;
        border-radius: 20px;
        background: ${colors.navy};
    }
    text-align: center;
    padding-top: 6em;
    h1 {
        margin: 0em auto;
    }
    h1 {
        margin-bottom: 0.75em;
    }
    .breadcrumbs {
        justify-content: space-around;
        align-items: center;
        display: flex;
        width: 280px;
        margin: 1em auto;
        div {
            height: 12px;
            position: relative;
            top: 0;
        }
    }

    @media ${device.tablet} {
        display: flex;
        padding: 8em 0em 2em;
        margin: 0 auto;
        width: 90vw;
        flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'reverse')};
        .regular-hero-image {
            border-radius: 20px;
            width: 400px;
        }
        h1 {
            margin-bottom: 0.5em;
            text-align: left;
            max-width: 450px;
        }
        .breadcrumbs {
            width: 340px;
            margin: 1em 0em 1em 0em;
        }
        .text-container {
            padding-left: 5em;
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: center;
        }
    }
    @media ${device.laptop} {
        max-width: 1300px;
        justify-content: center;
        h1 {
            line-height: 68px;
        }
        .text-container {
            padding-left: 4em;
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: center;
        }
        .services-hero-image {
            border-radius: 20px;
            width: 400px;
        }
    }
    @media ${device.laptopL} {
        .text-container {
            padding-left: 8em;
        }
    }
    @media ${device.desktop} {
        max-width: 1540px;
        .text-container {
            padding-left: 6em;
        }
        h1 {
            text-align: left;
            max-width: 550px;
            font-size: 76px;
            line-height: 89px;
        }
    }
    @media ${device.desktopL} {
        h1 {
            margin: 0.24em 0em;
        }

        .text-container {
            padding-left: 2em;
        }
    }
`;
interface Props {
    currentPage: string;
    prevPage: string;
    reverse?: boolean;
    navy?: boolean;
}

type HeroProps = {
    reverse?: boolean;
    navy?: boolean;
};

export const ServicesHero: React.FC<Props> = ({
    navy,
    reverse,
    currentPage,
    prevPage,
    children,
}) => {
    return (
        <StyledHero navy={navy} reverse={reverse}>
            <div className="text-container">
                <div className="breadcrumbs">
                    <Link to="/">
                        <p
                            style={{
                                paddingRight: '.25em',
                            }}
                        >
                            Home
                        </p>
                    </Link>
                    <StaticImage
                        src="../images/svgs/arrow-right-black.svg"
                        alt="black arrow pointing right"
                        placeholder="none"
                    />
                    <p
                        style={{
                            padding: '0em .6em',
                            background: `${colors.white}`,
                            marginLeft: '.25em',
                            borderRadius: '14px',
                        }}
                    >
                        {currentPage}
                    </p>
                </div>
                <div>
                    <h1>{currentPage}</h1>
                </div>
            </div>
            <div>{children}</div>
        </StyledHero>
    );
};
